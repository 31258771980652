import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import './App.css';
import HomeRoute from './routes/HomeRoute';
import Header from './components/Header';

function App() {
  return (
    <>
    <Header/>
    <Router>
      <Switch>
        <Route path="/" exact render={()=><HomeRoute/>} />
        <Redirect path="/" to="/" />
      </Switch>
    </Router>
    </>
  )
}

export default App;
