import React from "react";
import "./scss/Hero.scss";
import Symbol from "./icons/Symbol";
import Instagram from "./icons/Instagram";
export default function Hero() {
  return (
    <div id="Hero" className="Hero">
      <span className="Hero-logo">
        <Symbol />
      </span>
      <h1 className="Hero-header">Kayla Rose</h1>
      <a
        className="Hero-instagram"
        href="https://www.instagram.com/ruby_rose68/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Instagram />
      </a>
    </div>
  );
}
