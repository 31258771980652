import React from 'react'

export default function Symbol({className}) {
  return (
<svg
  height="48px"
  width="48px"
  className={className|''}
  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 486.4 337.6">
  <path d="M213.2,433.21q-9.6,0-21.8-9.8a203.28,203.28,0,0,1-26-25.8,405,405,0,0,1-27-35.2q-13.2-19.2-25-38.6t-20.2-35.4L56.8,406q-2.8,9.6-10.2,13.8A42.16,42.16,0,0,1,30.4,425a148.3,148.3,0,0,1-16.8,1q5.59-12.4,11.2-26.6t12.4-34.2q6.79-20,16.6-48.4T77.6,248q14-40.39,34-96,.8-2.79,2.8-7.8a25.4,25.4,0,0,0,2-9.4,8.09,8.09,0,0,0-1.8-5.6q-1.8-2-6.2-2-9.6,0-18,7.4A81.23,81.23,0,0,0,75.2,153a105.44,105.44,0,0,0-10.6,22.6q-3.81,11.61-3.8,20.4a45.73,45.73,0,0,0,4,18.4q-17.2,0-24.6-7.4t-7.4-18.6q0-13.59,8.4-27.4a108.53,108.53,0,0,1,21.4-25.2,121.31,121.31,0,0,1,27.6-18.4q14.6-7,27-7,13.59,0,17.8,7.4a31.52,31.52,0,0,1,4.2,15.8,42.91,42.91,0,0,1-.8,8.4,64.34,64.34,0,0,1-2,7.6l-29.6,96q4.4-2.79,10.2-7.2l17.4-13.2q22.4-17.6,43.2-36.8A508.36,508.36,0,0,0,216,149a363.79,363.79,0,0,0,30.4-39.8,21.44,21.44,0,0,1,6.4,11,47.31,47.31,0,0,1,1.6,10.6q0,12.41-10.2,27T217.4,188q-16.6,15.6-36.2,30.8T142,247.21q-19.6,13.2-35.2,23.2,12.8,19.6,24.6,37.2a384.34,384.34,0,0,0,26.2,34.6,425.85,425.85,0,0,0,33.8,35.2q19.4,18.21,46.6,39a33.5,33.5,0,0,1-10.8,11.8Q220,433.21,213.2,433.21Z" transform="translate(-13.6 -109.21)"/>
  <path d="M453.2,446.81q-9.6,0-22-10.4t-25.8-27.2a445.27,445.27,0,0,1-26.4-37q-13-20.19-24.6-40.6t-19.6-36.8q-10.8,36.81-20.6,72.6t-18.6,66.2a40.26,40.26,0,0,1-21.8-6.4q-10.2-6.4-10.2-16.8a33.49,33.49,0,0,1,2-10.4q2-6,3.6-11.6,11.6-34.8,20.8-62.2t17.4-50.4q8.19-23,16.8-45.6t19-47.4q10.4-24.8,24-56a136.43,136.43,0,0,0-27.4,8.4q-11.81,5.21-19.2,9.2t-10.2,4c-1.34,0-3.34-.86-6-2.6a28.76,28.76,0,0,1-7-6.4q-3-3.79-3-6.6,11.19-4.4,26.2-9.6a275.74,275.74,0,0,1,33-9,179.86,179.86,0,0,1,37.2-3.8,173.92,173.92,0,0,1,37.2,4.2A128.56,128.56,0,0,1,463.6,128,76.57,76.57,0,0,1,490,152q10,14.81,10,35.6,0,18.81-8.4,32.6a76.39,76.39,0,0,1-22.4,23.4,133.81,133.81,0,0,1-30.8,15.6,237.84,237.84,0,0,1-34,9.4,285.06,285.06,0,0,1-31.8,4.6q-14.61,1.2-24.2,1.6,12.8,20.4,25.2,39.6t26.6,38.2a362.17,362.17,0,0,0,33,38,430.58,430.58,0,0,0,44.4,38.6q-1.61,8-9.8,12.8T453.2,446.81ZM344,262.81a247.38,247.38,0,0,0,44.2-7.2A163.34,163.34,0,0,0,427.8,240q17.79-10,28.4-25t10.6-35.4q0-20.79-10.4-32.6a58.51,58.51,0,0,0-26.8-17,122.61,122.61,0,0,0-34.4-5.6q-13.6,26.81-26.4,63T344,262.81Z" transform="translate(-13.6 -109.21)"/>
</svg>
  )
}
