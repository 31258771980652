import React, { useEffect, useRef } from "react";
import "./scss/FeedContainer.scss";
import image_1 from "../assets/images/image_1.jpg";
import image_2 from "../assets/images/image_2.jpg";
import image_3 from "../assets/images/image_3.jpg";
import image_4 from "../assets/images/image_4.jpg";
import image_5 from "../assets/images/image_5.jpg";
import image_6 from "../assets/images/image_6.jpg";
import image_7 from "../assets/images/image_7.jpg";
import image_8 from "../assets/images/image_8.jpg";
import image_9 from "../assets/images/image_9.jpg";
import image_10 from "../assets/images/image_10.jpg";
import image_11 from "../assets/images/image_11.jpg";
import image_12 from "../assets/images/image_12.jpg";
import image_13 from "../assets/images/image_13.jpg";
import image_14 from "../assets/images/image_14.jpg";
import image_15 from "../assets/images/image_15.jpg";
import image_16 from "../assets/images/image_16.jpg";
import image_17 from "../assets/images/image_17.jpg";
import image_18 from "../assets/images/image_18.jpg";

export default function FeedContainer() {
  const modalRef = useRef(null)
  const images = [
    image_1,
    image_2,
    image_3,
    image_4,
    image_5,
    image_6,
    image_7,
    image_8,
    image_9,
    image_10,
    image_11,
    image_12,
    image_13,
    image_14,
    image_15,
    image_16,
    image_17,
    image_18,
  ];

  const handleModalClick = ({target}) => {
    console.log('handleModalClick', target)
    if(target.classList.contains('FeedContainer-modal')){
      modalRef.current.classList.remove('open')
      const modalContainer = modalRef.current.querySelector('.FeedContainer-modal-container')
      setTimeout(()=> {
        modalContainer.innerHTML = ''
      }, 300)
      console.log('handleModalClick: close')
    }
    }
    
    const handleItemClick = ({target}) => {
      let image
      if(target.className==='FeedContainer-item-image'){
        image = target
      } else {
        image = target.querySelector('.FeedContainer-item-image')
      }
      if(image){
        const modalContainer = modalRef.current.querySelector('.FeedContainer-modal-container')
        modalContainer.innerHTML = ''
        modalRef.current.classList.add('open')
        modalContainer.appendChild(image.cloneNode())
        console.log('image', {image, modalContainer})
      }
  }

  useEffect(() => {
    setTimeout(() => {
      let observer = new IntersectionObserver((enteries, observer) => {
        enteries.forEach((entry) => {
          const { target, isIntersecting } = entry;
          if (isIntersecting) target.classList.add("reveal");
        });
      });
      document
        .querySelectorAll(".FeedContainer-item")
        .forEach((imageItem) => observer.observe(imageItem));
    }, 500);
  }, []);

  return (
    <>
      <div id="Feed" className="FeedContainer">
        <div className="FeedContainer-modal" ref={modalRef} onClick={handleModalClick}>
          <div className="FeedContainer-modal-container"></div>
        </div>
        {images.reverse().map((image, idx) => (
          <div className="FeedContainer-item" onClick={handleItemClick} key={`image-${idx}`}>
            <img
              className="FeedContainer-item-image"
              src={image}
              loading="lazy"
              alt="Portfolio Item"
            />
          </div>
        ))}
      </div>
    </>
  );
}
