import React from 'react'
import './scss/Header.scss'
import Symbol from './icons/Symbol'
export default function Header() {
  return (
    <div id="Header" className="Header">
    <div className="Header-container">
      <div className="Header-logo">
        <Symbol/>
      </div>
      <div className="Header-links"></div>
      <div className="Header-fadeEdge"></div>
    </div>
    </div>
  )
}
