import React from 'react'
import './scss/HomeView.scss'
import Hero from '../../components/Hero.js'
import FeedContainer from '../../components/FeedContainer.js'
export default function HomeView() {
  return (
    <div className="HomeView">
      <Hero/>
      <FeedContainer/>
    </div>
  )
}
